import { Box } from "@chakra-ui/react";
import React from "react";

import Background from "~/assets/background.jpeg";
import BackgroundMobile from "~/assets/background-mobile.png";
import ConnectBackground from "~/assets/connect-wallet-bg.jpg";
import ConnectBackgroundMobile from "~/assets/connect-wallet-mobile-bg.png";

export default function Layout({
  children,
  connectedWallet = true,
}: {
  children: React.ReactNode;
  connectedWallet?: boolean;
}) {
  return (
    <Box
      justifyContent={{
        base: "flex-start",
        md: "center",
      }}
      backgroundImage={{
        base: `url(${
          connectedWallet ? BackgroundMobile.src : ConnectBackgroundMobile.src
        })`,
        md: `url(${connectedWallet ? Background.src : ConnectBackground.src})`,
      }}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition={{
        base: "top center",
        md: "center right",
      }}
      backgroundColor="#0D3551"
      h="100vh"
    >
      {children}
    </Box>
  );
}
