/* eslint-disable sonarjs/no-duplicate-string */
import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

export default function BoldText({ children, ...attr }: BoxProps) {
  const textShadow = {
    md: '0 7px black, -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000',
    base: '0 4px black, -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000',
  };

  return (
    <Box
      textTransform="uppercase"
      textShadow={textShadow}
      fontWeight="900"
      fontSize={{ md: '50px', base: '40px' }}
      letterSpacing="1px"
      textAlign="center"
      lineHeight="shorter"
      color="brand.200"
      {...attr}
    >
      {children}
    </Box>
  );
}
